import './index.html';
import './style.scss';
import {gsap} from 'gsap';
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';

export default async (header) => {


  const nav = header.querySelector('nav');
  const menuLinks = header.querySelectorAll('.menu-item');
  const burgerMenuCircle = header.querySelector('.burger-menu-circle');
  const submenuLinks = header.querySelectorAll('.header-more-links .hover-content');
  // const menuLinks = block.querySelectorAll('.menu-item');

  const menuLinksTl = gsap.timeline({
    paused: true,
    ease: 'linear',
  })
    .fromTo(nav, {pointerEvents: 'none', y: 10, autoAlpha: 0}, {
      pointerEvents: 'auto',
      y: 0,
      autoAlpha: 1
    })
    .fromTo(burgerMenuCircle, {
      width: 0,
      height: 0,
    }, {
      width: '500vh',
      height: '500vh',
      duration: 0.5,
    })
    .fromTo([menuLinks, submenuLinks], {
      y: 30,
      autoAlpha: 0,
    }, {
      y: 0,
      autoAlpha: 1,
      stagger: .05,
      duration: 0.2,
      clearProps: true,
    });


  header.classList.toggle('sticky', window.scrollY >= 20);
  window.addEventListener('scroll', function () {
    header.classList.toggle('sticky', window.scrollY >= 20);
  });


  // region open sub menu in responsive
  const mobileMedia = window.matchMedia('(max-width: 600px)');

  const menuItems = header.querySelectorAll('.menu-item-has-children');
  menuItems.forEach((menuItem) => {
    const menuItemBody = menuItem.querySelector('.sub-menu');
    menuItem?.addEventListener('click', (e) => {
      if (e.target !== menuItem) return;
      if (!mobileMedia.matches) return;
      if (!menuItemBody) {
        return;
      }
      const isOpened = menuItem?.classList.toggle('active-page');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
        menuItem.classList.remove('active');
      } else {
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
        menuItem.classList.add('active');
      }
    });
  });
  // endregion open sub menu in responsive

  const deskTop = window.matchMedia('(min-width: 600px)');
  if (deskTop.matches) {
    let subMenus = header.querySelectorAll('.sub-menu');
    let links = header.querySelectorAll('.menu-item-has-children');

    for (let i = 0; i < links.length; i++) {
      // subMenus[i].classList.remove('sub-menu-active');
      links[i].addEventListener('mouseenter', function () {
        for (let j = 0; j < links.length; j++) {
          subMenus[j].classList.remove('sub-menu-active');
          links[j].classList.remove('active');

        }
        subMenus[i].classList.add('sub-menu-active');
        links[i].classList.add('active');
      })
    }
  }


  animations(header);
  imageLazyLoading(header);

// handle svg color
  const blocks = document.querySelectorAll(".cyberHill-block");
  const logoAndBurgerColor = (isDark) => isDark ? "#1D4070" : "#ffffff";
  const getBlocksPositionBottom = () => [...blocks].map((el) => el.getBoundingClientRect().bottom)

  const handleSvgColor = ({
                            svg,
                            pathEl,
                            checkClass,
                            strokeValue = null,
                            stops
                          }) => {
    const {top: svgTop, bottom: svgBottom} = svg.getBoundingClientRect();

    const svgHeight = svg.clientHeight;

    const {paddingTop: svgPdTop, paddingBottom: svgPdBottom} =
      window.getComputedStyle(svg);

    const blocksBottom = getBlocksPositionBottom();

    let isBlockDark;

    for (let i = 0; i < blocksBottom.length; i++) {
      isBlockDark = blocks[i].classList.contains(checkClass);

      if (blocksBottom[i] >= svgBottom) {
        if (strokeValue) {
          pathEl?.setAttribute('stroke', logoAndBurgerColor(isBlockDark));

        } else {
          pathEl?.setAttribute("fill", "currentColor");
          svg.style.color = logoAndBurgerColor(isBlockDark);
        }

        break;
      } else if (blocksBottom[i] < svgBottom && blocksBottom[i] > 0 && blocksBottom[i] > svgTop) {

        if (strokeValue) {
          pathEl?.setAttribute('stroke', strokeValue)
        } else {
          pathEl?.removeAttribute("fill");
        }

        const firstColorHeight =
          ((blocksBottom[i] - svgTop - parseInt(svgPdTop)) /
            (svgHeight - parseInt(svgPdTop) - parseInt(svgPdBottom))) * 100;

        stops[0].setAttribute(
          "stop-color",
          `${logoAndBurgerColor(isBlockDark)}`
        );
        stops[1].setAttribute("offset", `${firstColorHeight}%`);
        stops[1].setAttribute(
          "stop-color",
          `${logoAndBurgerColor(isBlockDark)}`
        );
        stops[2].setAttribute("offset", `${firstColorHeight}%`);
        stops[2].setAttribute(
          "stop-color",
          `${logoAndBurgerColor(blocks[i + 1].classList.contains(checkClass))}`
        );
        stops[3].setAttribute(
          "stop-color",
          `${logoAndBurgerColor(blocks[i + 1].classList.contains(checkClass))}`
        );

        break;
      }
    }
  };


  // handle logo color
  const logo = document.getElementById("logoText");
  const logoStops = document.querySelectorAll(".logo-stop")
  const logoPath = document.getElementById("logoTextPath");
  const logoSvgInfo = {
    svg: logo,
    checkClass: 'logo-dark',
    stops: logoStops,
    pathEl: logoPath
  }

  const handleLogoColor = () => {
    handleSvgColor(logoSvgInfo);
  };


  // handle burger menu color
  const menuShapes = document.querySelectorAll('.menu-shape')
  const burgerMenu = document.getElementById("burger-menu")
  const burgerStops = document.querySelectorAll(".burger-stop")
  const circle = document.getElementById("circle")

  const burgerBorderSvgInfo = {
    svg: burgerMenu,
    pathEl: circle,
    checkClass: 'burger-dark',
    strokeValue: "url(#gradient)",
    stops: burgerStops
  }

  const handleBurgerMenuShapeColor = (el) => {
    const {bottom: shapeBottom} = el.getBoundingClientRect();
    const blocksBottom = getBlocksPositionBottom();
    for (let i = 0; i < blocksBottom.length; i++) {
      if (blocksBottom[i] >= shapeBottom) {
        el.style.background = logoAndBurgerColor(blocks[i].classList.contains("burger-dark"))
        break;
      }
    }
  };

  const handleBurgerMenuColor = () => {
    menuShapes.forEach(el => handleBurgerMenuShapeColor(el))
    handleSvgColor(burgerBorderSvgInfo)
  }

  const fillSvgColor = (el) => {
    logo.style.color = logoAndBurgerColor(el?.classList.contains("logo-dark"));
    logoPath.setAttribute("fill", "currentColor");
    circle.setAttribute('stroke', logoAndBurgerColor(el?.classList.contains("burger-dark")))
    for (let i = 0; i < menuShapes.length; i++) {
      menuShapes[i].style.background = logoAndBurgerColor(el?.classList.contains("burger-dark"));
    }
  }

  const updateLogoAndBurgerColor = (el = null, isActive = false) => {
    if (header.classList.contains("sticky")) {
      fillSvgColor(header);
      return null;
    }
    if (isActive) {
      fillSvgColor(el);
    } else {
      handleLogoColor()
      handleBurgerMenuColor()
    }
  }

  window.addEventListener('header-update-colors', function ({detail}) {
    updateLogoAndBurgerColor(detail, true)
  })


  const handleLogoAndBurgerColorInResize = () => {
    if (deskTop.matches) {
      updateLogoAndBurgerColor();
      window.addEventListener("scroll", updateLogoAndBurgerColor);
    } else {
      updateLogoAndBurgerColor(header, true);
    }
  }

  handleLogoAndBurgerColorInResize();

  window.addEventListener("resize", () => {
    if (window.innerWidth < 600) {
      updateLogoAndBurgerColor(header, true);
    } else {
      updateLogoAndBurgerColor();
    }
    window.removeEventListener("scroll", updateLogoAndBurgerColor)

  })

  const megaMenu = document.getElementById("megaMenu")

  header.querySelector(".menu").addEventListener("click", function () {
    const isActive = header.querySelector(".menu").classList.toggle("open");
    if (isActive) {
      document.documentElement.style.overflow = 'hidden';
      header.classList.add('active');
      menuLinksTl.play()
      updateLogoAndBurgerColor(megaMenu, true)
    } else {
      document.documentElement.style.overflow = 'auto';
      header.classList.remove('active');
      menuLinksTl.reverse()
      handleLogoAndBurgerColorInResize()
    }
  })


};

/**
 * Call this function to update the logo colors according to the underlying element
 * @param element {HTMLElement} this element will be used to control the logo's colors
 */
export const updateColors = element => {
  const event = new CustomEvent('header-update-colors', {detail: element})
  window.dispatchEvent(event);
}

