import './index.html';
import './style.scss';
import {imageLazyLoading} from "../../scripts/functions/imageLazyLoading";
import {animations} from "../../scripts/general/animations";
import {gsap} from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import {SplitText} from "gsap/SplitText";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";


gsap.registerPlugin(DrawSVGPlugin, SplitText, ScrollToPlugin);

const heroBlock = async (block) => {


  function startAnimation() {

    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';

    const loadingPage = block.querySelectorAll(".loading-page");
    // const loadingPage = block.querySelectorAll(".loading-page");
    for (let loadingPageElement of loadingPage) {
      loadingPageElement.classList.remove('loading-page');
    }

    function showContent() {
      const loadingPage = block.querySelectorAll(".hidden-animation");
      for (let loadingPageElement of loadingPage) {
        loadingPageElement.classList.remove('hidden-animation');
      }
    }

    let tl = gsap.timeline({
      defaults: {ease: "linear"}
    }).timeScale(1.5);

    function active() {
      block.querySelector(".shape-draw").classList.add("active")
    }

    let $quote = (".headline-1"),
      mySplitText = new SplitText($quote, {type: "words"}),
      splitTextTimeline = gsap.timeline();
    mySplitText.split({type: "lines"})
    gsap.set(block.querySelector(".intro-2"), {autoAlpha: 0})
    gsap.set(mySplitText.lines, {opacity: 0, y: 20});

    function splitText() {
      splitTextTimeline.to(mySplitText.lines, {
        duration: .6,
        opacity: 1,
        y: 0,
        transformOrigin: "50% 0% -150",
        stagger: 0.2
      });
    }

    gsap.set(block.querySelector(".background-overlay"), {
      opacity: 0,
    })
    tl
      .set(block.querySelectorAll(".intro-1-draw"), {startAt: {drawSVG: '0% 0%'}})
      .to(block.querySelector(".solid_background"),{
        opacity: 0,
        duration: 1,
        ease:"power3.in"
      },"+=1")
      .to(block.querySelector('.cyberhill-init-blue-mask'), {
        x: '-100%',
        duration: 1.5,
        delay: 1,
        ease: "linear"
      })
      .to(block.querySelectorAll(".intro-1-draw"), {
        drawSVG: '70% 100%',
        duration: 2
      },"-=.6")
      .to(block.querySelectorAll(".intro-1-draw"), {
        drawSVG: '100% 100%',
        duration: .3
      })
      .add(active, "<-.5")
      .to(block.querySelectorAll(".shape-draw"), {
        opacity: 0,
        duration: .5,
        onComplete: () => {
          gsap.to(block.querySelector(".background-overlay"), {
            opacity: 1,
            duration: 5
          })
        }
      }, "<.2")
      .from(block, {background: "white"})
      .set(block.querySelector(".intro-2"), {autoAlpha: 1})
      .set(block.querySelectorAll(".draw2"), {startAt: {drawSVG: '0% 0%'}})
      .set(block.querySelectorAll(".draw"), {startAt: {drawSVG: '0% 0%'}})
      .to(block.querySelectorAll(".draw"), {drawSVG: '70% 100%', duration: 2})
      .to(block.querySelectorAll(".draw"), {
        drawSVG: '100% 100%',
        duration: .5
      })
      .to(block.querySelectorAll(".draw2"), {
        drawSVG: '70% 100%',
        duration: 2.5
      }, ">-2")
      .to(block.querySelectorAll(".draw2"), {
        drawSVG: '100% 100%',
        duration: .5,
        ease: "power1.out"
      })
      .add(showContent,"<-3")
      .from(document.querySelector(".header-logo"), {
        x: -50,
        opacity: 0,
        ease: "power1.out"
      }, "<")
      .from(document.querySelector(".menu"), {
        x: 50,
        opacity: 0,
        ease: "power1.out"
      }, "<")
      .add(splitText, "<")
      .from(block.querySelector(".scroll-down"), {
        x: -50,
        opacity: 0,
        ease: "power1.out", onComplete: () => {
          document.body.style.overflow = 'auto';
          document.body.style.height = 'auto';
        }
      }, "<");

    // region scroll down function

    block.querySelector('.scroll-down')?.addEventListener('click', () => {
      // window.scrollTo({
      //   // top: block.offsetHeight,
      //   // behavior: 'smooth',
      // })
      gsap.to(window, {duration: .3, scrollTo: block.offsetHeight});
    })
  }


  const localStorageKey = `cyberHillVisitedBefore`;
  const isVisitedBefore = localStorage.getItem(localStorageKey);
  if (!isVisitedBefore) {
    localStorage.setItem(localStorageKey, 'true');
    block.querySelector('.hide-me-hero-animation').style.display = 'block';
    startAnimation()
  }


  block.querySelector('.scroll-down')?.addEventListener('click', () => {
    window.scrollTo({
      top: block.offsetHeight,
      behavior: 'smooth',
    })
    gsap.to(window, {duration: .3, scrollTo: block.offsetHeight});
  })

  animations(block);
  imageLazyLoading(block);
};

export default heroBlock;

