import {gsap} from "gsap";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import {stringToHTML} from "./stringToHTML";

gsap.registerPlugin(DrawSVGPlugin);

const a = navigator.userAgent || navigator.vendor || window.opera;

const css = (element, style) => {
  for (const property in style)
    element.style[property] = style[property];
}

const addAnimation = (block, container) => {
  const svg = stringToHTML(`
  <svg  preserveAspectRatio="none" data-name="Layer 1" class="intro loading-page"
       viewBox="0 0 685.43 392.08">
    <path  class="lineOne"
          d="M473.63,391.77l-185.1-185.1s-42.2-43.7-92.9-43.7-80.4,18.8-107.8,46.1S.33,295.77.33,295.77s45.2-34.4,98.3-34.4,78.9,25.8,90.6,35.9,93.7,93.7,93.7,93.7Z"
          fill="none" stroke="#82fa81" stroke-miterlimit="10"/>
    <path  class="lineTwo"
          d="M.33,162.87,185.43,348s42.2,43.7,92.9,43.7,80.4-18.8,107.8-46.1,87.5-86.7,87.5-86.7-45.2,34.4-98.3,34.4-78.9-25.8-90.6-35.9-93.7-93.7-93.7-93.7h-.2l-13.5-.1"
          fill="none" stroke="#1d4070" stroke-miterlimit="10"/>
    <path  class="lineTwo"
          d="M685.43,229.17,500.33,44.07S458.13.37,407.43.37s-80.4,18.8-107.8,46.1-87.5,86.7-87.5,86.7,45.2-34.4,98.3-34.4,78.9,25.8,90.6,35.9,93.7,93.7,93.7,93.7Z"
          fill="none" stroke="#82fa81" stroke-miterlimit="10"/>
    <path  class="lineOne"
          d="M208.23.37l185.1,185.1s42.2,43.7,92.9,43.7,80.4-18.8,107.8-46.1,87.5-86.7,87.5-86.7-45.2,34.4-98.3,34.4-78.9-25.8-90.6-35.9-93.7-93.7-93.7-93.7h-.2l-13.5-.1"
          fill="none" stroke="#1d4070" stroke-miterlimit="10"/>
  </svg>
`);
  block.prepend(svg[0]);

  const intro = container.querySelector(".intro");
  let tl = gsap.timeline({
    defaults: {ease: "linear"},
    scrollTrigger: {
      trigger: block,
    }
  }).timeScale(1.7);

  tl
    .set(intro, {autoAlpha: 1})
    .set(container.querySelectorAll(".lineTwo"), {startAt: {drawSVG: '0% 0%'}})
    .set(container.querySelectorAll(".lineOne"), {startAt: {drawSVG: '0% 0%'}})
    .to(container.querySelectorAll(".lineOne"), {
      drawSVG: '70% 100%',
      duration: 2,
      delay: 3
    })
    .to(container.querySelectorAll(".lineOne"), {
      drawSVG: '100% 100%',
      duration: .5
    })
    .to(container.querySelectorAll(".lineTwo"), {
      drawSVG: '70% 100%',
      duration: 2.5
    }, ">-2")
    .to(container.querySelectorAll(".lineTwo"), {
      drawSVG: '100% 100%',
      duration: .5,
      ease: "power1.out"
    })

}

export function insertLinesAnimation(container) {
  const blocks = container.querySelectorAll(".cyberHill-block");
  blocks.forEach(el => el.classList.contains('has_lines_animation') && addAnimation(el, container))
  const intros = container.querySelectorAll(".intro") || [];
  intros.forEach(el => css(el, {
    'position': 'absolute',
    'width': '140%',
    'height': '100%',
    'left': '50%',
    'top': '50%',
    'transform': 'translate(-50%, -50%)',
    'z-index': '5',
    'pointer-events': 'none'
  }))
}
